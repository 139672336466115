import React, { useState, useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import { withRouter, useLocation } from "react-router-dom";
import ReactGA from 'react-ga';
import CookieConsent from "react-cookie-consent";
import "./App.css";

function App(props) {
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);

    useEffect(() => {
        onLoad();
    }, []);

    // ...

    async function onLoad() {
        try {
            await Auth.currentSession();
            userHasAuthenticated(true);
        }
        catch(e) {
            if (e !== 'No current user') {
                // TODO: what to do here?
                console.log("Error waiting for auth: " + e.message);
            }
        }

        setIsAuthenticating(false);
    }

    async function handleLogout() {
        await Auth.signOut();

        userHasAuthenticated(false);

        props.history.push("/login");
    }

    const params = new URLSearchParams(useLocation().search);

    function googleAnalytics() {
        ReactGA.initialize('UA-155338739-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    if (params.get("login")) {
        props.history.push("/login");
        return <p></p>;
    } else {
        return (
            !isAuthenticating &&
                <div className="App container">
                <CookieConsent
                    enableDeclineButton
                    buttonText="I Accept"
                    declineButtonText="I Decline"
                    buttonStyle={{ background: "LightGreen" }}
                    onAccept={() => {googleAnalytics()}}>
                    BrunchIndex uses cookies to analyse traffic.
                </CookieConsent>
                <Navbar expand="lg">
                <Navbar.Brand href="/"><img src={process.env.PUBLIC_URL + '/brunchindex_logo_100px.png'} alt="BrunchIndex Logo" /><h1 className="navbar-brand">Brunch Index</h1></Navbar.Brand>
                        <Navbar.Toggle/>
                        <Navbar.Collapse className="justify-content-end">
                            <a href="https://twitter.com/brunchindex"><img className="twitter_logo" src={process.env.PUBLIC_URL + '/Twitter_Social_Icon_Rounded_Square_Color.png'} alt="Twitter Logo" /></a>
                            <a href="https://www.instagram.com/brunchindex"><img className="instagram_logo" src={process.env.PUBLIC_URL + '/instagram-glyph-logo_May2016.png'} alt="Instagram Logo" /></a>
                            <Nav variant="tabs" defaultActiveKey="/">
                                <LinkContainer to="/recipes">
                                    <Nav.Link>Recipes</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/about">
                                    <Nav.Link>About</Nav.Link>
                                </LinkContainer>
                                {isAuthenticated && <LinkContainer to="/venues/new"><Nav.Link>Create</Nav.Link></LinkContainer>}
                                {isAuthenticated && <Nav.Link onClick={handleLogout}>Logout</Nav.Link>}
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                    <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
                </div>
        );
    }
}

export default withRouter(App);
