const dev = {
    s3: {
        REGION: "eu-west-1",
        BUCKET: "brunchindex-api-dev-serverlessdeploymentbucket-1og7k7ux9ei4l",
        CONTENT_REGION: "eu-west-1",
        CONTENT_BUCKET: "brunchindex-api-dev-contentbucket-qt0ipkk71idf"
    },
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://dk3oyim790.execute-api.eu-west-1.amazonaws.com/dev"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_u6EcUgl8p",
        APP_CLIENT_ID: "7rlr93aqgsj4087mehlumqhtb8",
        IDENTITY_POOL_ID: "eu-west-1:6d8d81c8-2c54-4acf-b322-d48f1d296248"
    }
};

const prod = {
    s3: {
        REGION: "eu-west-1",
        BUCKET: "brunchindex-api-prod-serverlessdeploymentbucket-3wkg9w2s4vkv",
        CONTENT_REGION: "eu-west-1",
        CONTENT_BUCKET: "brunchindex-api-prod-contentbucket-11kgzrf9a0oyv"
    },
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://a0f57cjhb5.execute-api.eu-west-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_eYJDf1DAt",
        APP_CLIENT_ID: "5953j61hphd2hlfjor144lams4",
        IDENTITY_POOL_ID: "eu-west-1:ad9d8d65-2df2-4ecf-8cd3-ba1e9af81504"
    }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
