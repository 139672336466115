import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Recipes from "./containers/Recipes";
import Login from "./containers/Login";
import NewVenue from "./containers/NewVenue";
import UpdateVenue from "./containers/UpdateVenue";
import DeleteVenue from "./containers/DeleteVenue";
import About from "./containers/About";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";

export default function Routes({ appProps }) {
    return (
            <Switch>
            <AppliedRoute path="/" exact component={Home} appProps={appProps} />
            <AppliedRoute path="/recipes" exact component={Recipes} />
            <AppliedRoute path="/login" exact component={Login} appProps={appProps} />
            <AppliedRoute path="/venues/new" exact component={NewVenue} appProps={appProps} />
            <AppliedRoute path="/venues/update/:name" exact component={UpdateVenue} appProps={appProps} />
            <AppliedRoute path="/venues/delete/:name" exact component={DeleteVenue} appProps={appProps} />
            <AppliedRoute path="/about" exact component={About} />
            { /* Finally, catch all unmatched routes */ }
            <Route component={NotFound} />
            </Switch>
    );
}
