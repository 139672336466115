import React from "react";
import "./About.css";

export default function About() {
    return (
        <div className="About">

            <p>BrunchIndex is a curated list of great places to get brunch and breakfast in London.</p>

            <h3>Contact</h3>
            <p><a href="mailto: info@brunchindex.com">info@brunchindex.com</a></p>

        </div>
    );
}
