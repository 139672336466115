import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import "./NewVenue.css";

export default function NewVenue(props) {
    const [active, setActive] = useState(false);
    const [baconOrHam, setBaconOrHam] = useState("");
    const [bookable, setBookable] = useState("");
    const [brunchDays, setBrunchDays] = useState("");
    const [brunchHours, setBrunchHours] = useState("");
    const [coffeeBeans, setCoffeeBeans] = useState("");
    const [deliveryTakeaway, setDeliveryTakeaway] = useState("");
    const [district, setDistrict] = useState("");
    const [eggOptions, setEggOptions] = useState("");
    const [features, setFeatures] = useState("");
    const [franticFactor, setFranticFactor] = useState("");
    const [image, setImage] = useState("");
    const [mocha, setMocha] = useState("");
    const [name, setName] = useState("");
    const [pastryItems, setPastryItems] = useState("");
    const [tableSize, setTableSize] = useState("");
    const [veganOptions, setVeganOptions] = useState("");
    const [website, setWebsite] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const [validated, setValidated] = useState(false);

    async function handleSubmit(event) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        event.preventDefault();
        setIsLoading(true);

        try {
            await createVenue({ 'name': name,
                                'bacon_or_ham': baconOrHam,
                                'bookable': bookable,
                                'brunch_days': brunchDays,
                                'brunch_hours': brunchHours,
                                'coffee_beans': coffeeBeans,
                                'delivery_takeaway': deliveryTakeaway,
                                'district': district,
                                'egg_options': eggOptions,
                                'features': features,
                                'pastry_items': pastryItems,
                                'mocha': mocha,
                                'table_size': tableSize,
                                'vegan_options': veganOptions,
                                'website': website,
                                'active': active,
                                'image': image,
                                'frantic_factor': franticFactor
                              });
            props.history.push("/");
        } catch (e) {
            alert(e.message);
            setIsLoading(false);
        }
    }

    function createVenue(venue) {
        return API.post("brunchindex", "/brunchindex", {
            body: venue
        });
    }

  return (
    <div className="NewVenue">
      <h2>Create new brunch venue</h2>
      <Form Validate validated={validated} onSubmit={handleSubmit}>
        {/* name */}
        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control componentClass="text" value={name} required
            onChange={e => setName(e.target.value)} placeholder="Venue name"/>
        </Form.Group>
        {/* active */}
          <Form.Group controlId="active">
          <Form.Label>Active</Form.Label> <Form.Check type="checkbox" inline checked={active}
            onChange={e => setActive(e.target.checked)} placeholder="Active"/>
        </Form.Group>
        {/* bacon_or_ham */}
        <Form.Group controlId="bacon_or_ham">
          <Form.Label>Bacon or Ham?</Form.Label>
          <Form.Control componentClass="text" value={baconOrHam} required
            onChange={e => setBaconOrHam(e.target.value)} placeholder="Bacon or ham"/>
        </Form.Group>
        {/* bookable */}
        <Form.Group controlId="bookable">
          <Form.Label>Bookable?</Form.Label>
          <Form.Control componentClass="text" value={bookable} required
            onChange={e => setBookable(e.target.value)} placeholder="Bookable?"/>
        </Form.Group>
        {/* brunch_days */}
        <Form.Group controlId="brunch_days">
          <Form.Label>Brunch Days</Form.Label>
          <Form.Control componentClass="text" value={brunchDays} required
            onChange={e => setBrunchDays(e.target.value)} placeholder="Brunch days"/>
        </Form.Group>
        {/* brunch_hours */}
        <Form.Group controlId="brunch_hours">
          <Form.Label>Brunch Hours</Form.Label>
          <Form.Control componentClass="text" value={brunchHours} required
            onChange={e => setBrunchHours(e.target.value)} placeholder="Brunch hours"/>
        </Form.Group>
        {/* coffee_beans */}
        <Form.Group controlId="coffee_beans">
          <Form.Label>Coffee Beans</Form.Label>
          <Form.Control componentClass="text" value={coffeeBeans} required
            onChange={e => setCoffeeBeans(e.target.value)} placeholder="Coffee beans"/>
        </Form.Group>
        {/* delivery_takeaway */}
        <Form.Group controlId="delivery_takeaway">
          <Form.Label>Delivery/Takeaway</Form.Label>
          <Form.Control componentClass="text" value={deliveryTakeaway} required
            onChange={e => setDeliveryTakeaway(e.target.value)} placeholder="Delivery/Takeaway"/>
        </Form.Group>
        {/* district */}
        <Form.Group controlId="district">
          <Form.Label>District</Form.Label>
          <Form.Control componentClass="text" value={district} required
            onChange={e => setDistrict(e.target.value)} placeholder="District"/>
        </Form.Group>
        {/* egg_options */}
        <Form.Group controlId="egg_options">
          <Form.Label>Egg Options</Form.Label>
          <Form.Control componentClass="text" value={eggOptions} required
            onChange={e => setEggOptions(e.target.value)} placeholder="Egg options"/>
        </Form.Group>
        {/* features */}
        <Form.Group controlId="features">
          <Form.Label>Features</Form.Label>
          <Form.Control componentClass="text" value={features} required
            onChange={e => setFeatures(e.target.value)} placeholder="Features"/>
        </Form.Group>
        {/* frantic_factor */}
          <Form.Group controlId="frantic_factor">
          <Form.Label>Frantic Factor</Form.Label>
          <Form.Control componentClass="text" value={franticFactor} required
            onChange={e => setFranticFactor(e.target.value)} placeholder="Frantic factor"/>
        </Form.Group>
        {/* image */}
        <Form.Group controlId="image">
          <Form.Label>Image</Form.Label>
          <Form.Control componentClass="text" value={image}
            onChange={e => setImage(e.target.value)} placeholder="Image"/>
        </Form.Group>
        {/* mocha */}
        <Form.Group controlId="mocha">
          <Form.Label>Mocha</Form.Label>
          <Form.Control componentClass="text" value={mocha} required
            onChange={e => setMocha(e.target.value)} placeholder="Mocha"/>
        </Form.Group>
        {/* pastry_items */}
        <Form.Group controlId="pastry_items">
          <Form.Label>Pastry Items</Form.Label>
          <Form.Control componentClass="text" value={pastryItems} required
            onChange={e => setPastryItems(e.target.value)} placeholder="Pastry items"/>
        </Form.Group>
        {/* table_size */}
        <Form.Group controlId="table_size">
          <Form.Label>Table Size</Form.Label>
          <Form.Control componentClass="text" value={tableSize} required
            onChange={e => setTableSize(e.target.value)} placeholder="Table size"/>
        </Form.Group>
        {/* vegan_options */}
        <Form.Group controlId="vegan_options">
          <Form.Label>Vegan Options</Form.Label>
          <Form.Control componentClass="text" value={veganOptions} required
            onChange={e => setVeganOptions(e.target.value)} placeholder="Vegan options"/>
        </Form.Group>
        {/* website */}
        <Form.Group controlId="website">
          <Form.Label>Website</Form.Label>
          <Form.Control componentClass="text" value={website} required
            onChange={e => setWebsite(e.target.value)} placeholder="Website"/>
        </Form.Group>

        <LoaderButton
          block
          type="submit"
          bsSize="large"
          bsStyle="primary"
          isLoading={isLoading}
        >
          Create
        </LoaderButton>
      </Form>
    </div>
  );
}
