import React, { useState, useEffect } from "react";
import { Form, ListGroup, Tabs, Tab } from "react-bootstrap";
import { Link} from "react-router-dom";
import { API } from "aws-amplify";
import "./Home.css";
import config from '../config';

export default function Home(props) {
    const [venues, setVenues] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [key, setKey] = useState('');
    const [deliveryTakeawayOnly, setDeliveryTakeawayOnly] = useState(false);

    function getVenueMap(venueList) {
        return venueList.reduce((obj, item) => {
            var itemKey = item.district;
            if (obj.has(itemKey))
                obj.get(itemKey).push(item);
            else
                obj.set(itemKey, new Array(item));
            return obj;
        }, new Map());
    }

    function getDistricts(venues) {
        return Array.from(venues.keys()).sort();
    }

    function hasDeliveryTakeaway(venue) {
        return venue.delivery_takeaway && venue.delivery_takeaway !== "Closed during Covid-19 outbreak";
    }

    useEffect(() => {
        function loadVenueList() {
            if (props.isAuthenticated) {
                return API.get("brunchindex", "/brunchindex?list_inactive=true");
            } else {
                return API.get("brunchindex", "/brunchindex");
            }
        }
        async function onLoad() {
            var venueMap = null;
            try {
                const venueList = await loadVenueList();
                const filteredVenueList = venueList.filter(venue => !deliveryTakeawayOnly || hasDeliveryTakeaway(venue));
                venueMap = getVenueMap(filteredVenueList);
            } catch (e) {
                console.log("Error getting venue list: " + e.message);
            }

            setVenues(venueMap);

            if (key === "" || !venueMap.has(key)) {
                // TODO: setting key will cause our useEffect to fire again, a bit inefficient
                setKey(getDistricts(venueMap)[0]);
            }

            setIsLoading(false);
        }

        onLoad();
    }, [key, deliveryTakeawayOnly, props.isAuthenticated]);

    function getS3BucketURL() {
        return "https://" + config.s3.CONTENT_BUCKET + ".s3-" + config.s3.CONTENT_REGION + ".amazonaws.com";
    }

    function getImageURL(venue) {
        return getS3BucketURL() + "/" + venue.image;
    }

    function renderVenue(venue) {
        return (<ListGroup.Item key={venue.name}>
                <div className="venue">
                {props.isAuthenticated && (venue.active ? <div className="active_venue">Active</div> : <div className="inactive_venue">Inactive</div>)}
                <h3 className="venue_name"><a href={venue.website} target="_blank" rel="noopener noreferrer">{venue.name} <span className="external_link"><i className="fas fa-external-link-alt" /></span></a></h3>
                    {venue.image &&
                        <div className="photo">
                            <img src={getImageURL(venue)} alt={venue.name} />
                        </div>
                    }
                <p className={hasDeliveryTakeaway(venue) ? "delivery_ok" : "delivery_none"}>
                      <b>Delivery/Takeaway</b>: {venue.delivery_takeaway || "None at present"}
                    </p>
                    <p><b>Brunch days</b>: {venue.brunch_days}</p>
                    <p><b>Brunch hours</b>: {venue.brunch_hours}</p>
                    <p><b>Eggs Benedict/Royale/Florentine</b>: {venue.egg_options}</p>
                    <p><b>Bacon or ham?</b> {venue.bacon_or_ham}</p>
                    <p><b>Bookable?</b> {venue.bookable }</p>
                    <p><b>Vegan options?</b> {venue.vegan_options}</p>
                    <p><b>Pastry items?</b> {venue.pastry_items}</p>
                    <p><b>Coffee beans:</b> {venue.coffee_beans}</p>
                    <p><b>Mocha?</b> {venue.mocha ? "Yes" : "No"}</p>
                    <p><b>Table size: will it fit all my brunch?</b> {venue.table_size}</p>
                    <p><b>Features:</b> {venue.features}</p>
                    <p><b>Frantic factor:</b> {venue.frantic_factor}</p>
                {props.isAuthenticated &&
                  <div className="update_delete">
                    <Link to={"/venues/update/" + venue.name}>update</Link>|
                    <Link to={"/venues/delete/" + venue.name}>delete</Link>
                  </div>}
                </div>
                </ListGroup.Item>
               );
    }


    function renderDistrict(district, venues) {
        return (<Tab eventKey={district} title={district}>
                  <div className="district">
                    <ListGroup variant="flush">
                      { venues.sort( (a, b) => b.updated_at - a.updated_at ).map( venue => renderVenue(venue) ) }
                    </ListGroup>
                  </div>
                </Tab>);
    }

    function renderAllDistricts(venues) {
        return (<div>
                <Tabs
                  activeKey={key}
                  onSelect={(k) => setKey(k)}>
                  { getDistricts(venues).map( district => renderDistrict(district, venues.get(district)) ) }
                </Tabs>
                </div>);
    }

    function DistrictList(props) {
        const districts = getDistricts(venues);

        function joinWithAnd(items) {
            const lastTwo = items.splice(-2, 2);
            if (lastTwo.length < 2) {
                return lastTwo;
            }
            const front = items.reduce( (a, x) => a = a.concat(...[x, ", "]), []);
            lastTwo.splice(1, 0, " and ");
            return front.concat(lastTwo);
        }

        return (<span>{joinWithAnd(districts)}</span>);
    }

    function toggleDeliveryTakeawayOnly(deliveryTakeawayOnly) {
        setDeliveryTakeawayOnly(deliveryTakeawayOnly);
    }

    return (
        <div className="Home">
            <p>Want to know where to get a great brunch in London? Look no further!</p>

            <p>Brunch is the most awesome meal of the week. So, here is a curated selection of brunch spots for this weekend, or any day of the week, in <DistrictList/>.</p>

            <p>We have also included venues that provide a breakfast that is varied and leisurely enough to cross over into the brunch category.</p>


            <div className="covid19_update">
            <h4>Covid-19 Update</h4>
            <p>Even though we're all stuck indoors, brunch is still important!  We are now including information about delivery options during the Covid-19 outbreak.  We're also including some great <b><Link to="/recipes">recipes</Link></b> for you to make while you stay home and stay safe.</p>
            </div>

            <p>What are you waiting for? Your next brunch starts here!</p>

            <div className="toggle_delivery_takeaway_only">
            <Form.Group>
            <Form.Label>Delivery and takeaway only <span className="superscript_new">NEW!</span> <Form.Check inline type="checkbox" checked={deliveryTakeawayOnly} onChange={e => toggleDeliveryTakeawayOnly(e.target.checked)}/></Form.Label>
            </Form.Group>
            </div>

            <div className="venues">
                {!isLoading && renderAllDistricts(venues)}
            </div>

            <div className="contact">
                <a href="mailto: info@brunchindex.com"><i className="far fa-envelope"></i></a>
                <a href="https://twitter.com/brunchindex"><img className="twitter_logo" src={process.env.PUBLIC_URL + '/Twitter_Social_Icon_Rounded_Square_Color.png'} alt="Twitter Logo" /></a>
                <a href="https://www.instagram.com/brunchindex"><img className="instagram_logo" src={process.env.PUBLIC_URL + '/instagram-glyph-logo_May2016.png'} alt="Instagram Logo" /></a>
            </div>

            <p className="copyright">Copyright Brunch Index – All rights reserved</p>
        </div>
    );
}
