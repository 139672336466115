import React from "react";
import "./Recipes.css";

export default function Recipes() {
    return (
        <div className="Recipes">

            <h1>Recipes</h1>

            <p>Here are some great brunch recipes</p>

            <ul>
            <li><a href="https://gamechangersmovie.com/recipes/spelt-blueberry-pancakes"><b>Spelt blueberry pancakes</b> - Game Changers</a></li>
            <li><a href="https://www.ocado.com/webshop/recipeView.do?recipeId=122049"><b>Coconut overnight oats</b> - Ocado</a></li>
            <li><a href="https://www.bbcgoodfood.com/recipes/gordons-eggs-benedict"><b>Eggs benedict</b> - BBC Good Food</a></li>
            <li><a href="https://www.jamieoliver.com/recipes/eggs-recipes/pancakes-usa-stylie/"><b>American style pancakes</b> - Jamie Oliver</a></li>
            <li><a href="https://www.bbcgoodfood.com/recipes/tofu-scramble-0"><b>Tofu scramble</b> - BBC Good Food</a></li>
            <li><a href="https://www.bbcgoodfood.com/recipes/gordons-eggs-benedict"><b>Banana date muffins</b> - BBC Good Food</a></li>
            </ul>
        </div>
    );
}
