import React from "react";
import { API } from "aws-amplify";
import { encodeParam } from "./Common";
import "./DeleteVenue.css";

export default function DeleteVenue(props) {

    const venueName = props.match.params.name;

    async function handleDelete() {

        try {
            await deleteVenue(venueName);
            props.history.push("/");
        } catch (e) {
            alert(e.message);
        }
    }

    function deleteVenue(name) {
        return API.del("brunchindex", "/brunchindex/" + encodeParam(name));
    }

  return (
    <div className="DeleteVenue">
        <h2>Delete venue {venueName}?</h2>
        <button onClick={handleDelete}>
          Delete {venueName}
        </button>
    </div>
  );
}
